<template>
  <div>
    <el-table :data="gridData">
      <el-table-column prop="createTime" label="反馈时间"> </el-table-column>
        <el-table-column prop="remarks" label="反馈内容"> </el-table-column>
        <el-table-column prop="institutionUserName" label="员工"> </el-table-column>
        <el-table-column prop="score" label="分级"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { list_follow } from "../../api/OrderManagement";
export default {
  props: ["idc"],
  data() {
    return {
      gridData: [],
      queryList: {
        userId: 1,
        pageIdx: 1,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.queryList.userId = this.idc;
    list_follow(this.queryList).then((res) => {
      console.log(res.data.records);
      this.gridData = res.data.records
    });
  },
};
</script>

<style>
</style>